<template>
  <div>
    <section class="section is-main-section">
      <h1 class="title">
        {{ title }}
              <b-button
                style="margin-top:-7px;"
                  v-if="selectedItems && selectedItems.length > 0"
                  class="is-success is-pulled-right"
                  @click="isShowModalVehicle = true"
                  icon-left="check">
                  ບັນທຶກ
                </b-button>
      </h1>
      <div class="columns">
        <div class="column">
          <div class="panel is-primary">
            <div class="panel-heading">ລວມເຄື່ອງຝາກຕາມສາຂາປາຍທາງ</div>
            <div>
              <div class="" style="padding: 1em">
                <b-radio
                  v-model="isCod"
                  :native-value="'all'"
                  @input="filterCod"
                >
                  ທັງໝົດ
                </b-radio>
                <b-radio
                  v-model="isCod"
                  :native-value="'cod'"
                  @input="filterCod"
                >
                  COD
                </b-radio>
                <b-radio
                  v-model="isCod"
                  :native-value="'nocod'"
                  @input="filterCod"
                >
                  ບໍ່ມີ COD
                </b-radio>
              </div>

              <div style="padding: 1em">
                <table class="table is-fullwidth">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>ສາຂາ</th>
                      <th class="has-text-right">ຈຳນວນ</th>
                      <th class="has-text-right">ເລືອກ</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, index) in branches"
                      :key="index"
                      :class="toBranch == item ? 'is-selected' : ''"
                    >
                      <td>{{ index + 1 }}</td>
                      <td>{{ item.name }}</td>
                      <td class="has-text-right">{{ item.count }}</td>
                      <td class="has-text-right">
                        <b-button
                          icon-left="check"
                          class="is-small is-grey"
                          @click="selectBranch(item)"
                        >
                        </b-button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="column">
          <div class="panel">
            <div class="panel-heading">
              ເຄື່ອງຢູ່ສາຂາ
              <b-button
              style="margin-top:-7px;"
                outlined
                :disabled="models.length<1"
                class="is-primary is-pulled-right"
                @click="addall()"
              >
                >> ເອົາຂຶ້ນທັງໝົດ
              </b-button>
            </div>
            {{currentPage}}
            <b-table
              :loading="isLoading"
              :paginated="perPage < models.length"
              :per-page="perPage"
              :striped="true"
              :hoverable="true"
              default-sort="name"
              :data="models"
              :current-page.sync="currentPage"
              :pagination-rounded="true"
            >
              <b-table-column label="#" v-slot="props">
                {{ props.index+(currentPage>1?(perPage*currentPage-perPage):0)+1 }}
              </b-table-column>
              <b-table-column
                label="ລະຫັດ"
                field="code"
                sortable
                v-slot="props"
              >
                {{ props.row.code }}
              </b-table-column>
              <b-table-column
                custom-key="actions"
                cell-class="is-actions-cell"
                v-slot="props"
              >
                <div class="buttons is-right">
                  <b-button
                    class="is-small is-info"
                    icon-left="eye"
                    @click="view(props.row)"
                  >
                  </b-button>
                  <b-button
                    class="is-small is-primary"
                    icon-left="plus"
                    @click="add(props.row)"
                  >
                  </b-button>
                </div>
              </b-table-column>

              <empty-table v-if="models.length == 0" :isLoading="isLoading" />
            </b-table>
          </div>
        </div>
        <div class="column">
          <div class="panel is-warning">
            <div class="panel-heading">ເຄື່ອງຕຽມຂຶ້ນລົດ
            </div>
            <b-table
              :paginated="perPage < selectedItems.length"
              :per-page="perPage"
              :striped="true"
              :hoverable="true"
              default-sort="name"
              :data="selectedItems"
              :current-page.sync="currentPageToBus"
              :pagination-rounded="true"
            >
              <b-table-column label="#" v-slot="props">
                {{ props.index * currentPageToBus + 1 }}
              </b-table-column>
              <b-table-column
                label="ລະຫັດ"
                field="code"
                sortable
                v-slot="props"
              >
                {{ props.row.code }}
              </b-table-column>
              <b-table-column
                custom-key="actions"
                cell-class="is-actions-cell"
                v-slot="props"
              >
                <div class="buttons is-right">
                  <b-button
                    class="is-small is-info"
                    icon-left="eye"
                    @click="view(props.row)"
                  >
                  </b-button>
                  <b-button
                        class="is-small is-danger"
                        icon-left="minus"
                        @click="remove(props.row)"
                      >
                      </b-button>
                </div>
              </b-table-column>

              <empty-table v-if="selectedItems.length == 0" />
            </b-table>


          </div>
        </div>
      </div>
    </section>
    <div
      class="modal is-active is-large"
      v-if="isShowModalVehicle && selectedItems"
    >
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">{{ title }}</p>
          <button
            class="delete"
            aria-label="close"
            @click="isShowModalVehicle = false"
          ></button>
        </header>
        <section class="modal-card-body">
          <b-field label="ປ້າຍລົດ">
            <b-select v-model="selectedVehicle" placeholder="ປ້າຍລົດ" required>
              <option
                v-for="(vehicle, index) in vehicles"
                :key="index"
                :value="vehicle"
              >
                {{ vehicle.license_no }}
              </option>
            </b-select>
          </b-field>
        </section>
        <footer v-if="selectedVehicle" class="modal-card-foot has-text-right">
          <b-button @click="save()" class="is-success" icon-left="check">
            ບັນທຶກ
          </b-button>
        </footer>
      </div>
    </div>
    <b-loading
        :is-full-page="true"
        v-model="loadingFlag"
        :can-cancel="false"
    ></b-loading>
  </div>
</template>

<script>
import axios from "axios";
import swal from "sweetalert2";
import EmptyTable from "../components/EmptyTable.vue";
export default {
  name: "LoadParcelsToBus",
  components: { EmptyTable },
  data() {
    return {
      isCod: "all",
      currentPage: 1,
      currentPageToBus: 1,
      perPage: 50,
      title: "ເອົາເຄື່ອງຂຶ້ນລົດ",
      model: null,
      models: [],
      vehicles: [],
      branches: [],
      isShowModalVehicle: false,
      isLoading: false,
      selectedItems: [],
      selectedVehicle: null,
      toBranch: null,
      loadingFlag: false,
    };
  },
  mounted() {
    axios.get("vehicles").then((r) => {
      this.vehicles = r;
    });
    this.load();
  },
  watch: {
    //models: function () {
    //  this.isLoading = false;
    //},
  },
  methods: {
    load() {
      axios.get(`parcels/loads/${this.isCod}`).then((r) => {
        this.branches = r;
      });
    },
    filterCod(e) {
      this.isCod = e;
      this.selectBranch(null);
      this.load();
    },
    selectBranch(item) {
      this.toBranch = item;
      this.models = [];
      this.selectedItems = [];
      this.gets();
    },
    gets() {
      if (this.toBranch) {
        this.isLoading = true;
        axios.get(`parcels/loadtobus/${this.toBranch.id}`).then((r) => {
          this.models = r;
          this.isLoading = false;
        }).catch(e=>{
          console.log(e);
          this.isLoading = false;
        });
      }
    },
    view(item) {
      this.$router.push({ path: "/shipment/" + item.code });
    },
    addall() {
      if (!this.toBranch) {
        swal.fire("ກະລຸນາເລືອກສາຂາ", "", "warning");
        return;
      }
      this.selectedItems = this.models;
      this.models = [];
    },
    add(item) {
      if (!this.toBranch) {
        swal.fire("ກະລຸນາເລືອກສາຂາ", "", "warning");
        return;
      }
      const index = this.models.indexOf(item);
      this.models.splice(index, 1);
      if (!this.selectedItems) {
        this.selectedItems = [];
      }
      this.selectedItems.push(item);
    },
    remove(item) {
       if (!this.models) {
        this.models = [];
      }
      this.models.push(item);
      const index = this.selectedItems.indexOf(item);
      this.selectedItems.splice(index, 1);
      
     
    },
    save() {
      if (!this.toBranch) {
        swal.fire("ກະລຸນາເລືອກສາຂາ", "", "warning");
        return;
      }
      if(this.selectedItems.length === 0) {
        swal.fire("ກະລຸນາເລືອກພັດສະດຸກ່ອນ", "", "warning");
        return;
      }
      const body = {
        vehicle_id: this.selectedVehicle.id,
        items: this.selectedItems,
        toBranch: this.toBranch,
      };
      this.loadingFlag = true;
      axios.put("shipmentscript/create", body).then((r) => {
        this.loadingFlag = false;
        console.log(r);
        this.isShowModalVehicle = false;
        this.selectedItems = [];
        this.toBranch = null;
        this.selectedVehicle = null;
        this.gets();
        this.$router.push({ path: "/ShipmentScriptView/" + r.code });
      }).catch(() => {
        this.loadingFlag = false;
      });
    },
    branchOptions({ code, name }) {
      return `${code} — ${name}`;
    },
  },
};
</script>
